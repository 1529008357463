import * as React from "react";
import { AllergensProvider } from "./src/context/allergensContext";
import { LocationContext } from "./src/context/locationContext";
import { MenuDataProvider } from "./src/context/menuDataContext";

export const wrapPageElement = ({ element, props }) => (
	<LocationContext.Provider value={{ location: props.location }}>
		<MenuDataProvider>
			<AllergensProvider>{element}</AllergensProvider>
		</MenuDataProvider>
	</LocationContext.Provider>
);
