import * as React from "react";

// plugin imports
import axios from "axios";

// utils imports

// svg imports

export const RouteContext = React.createContext({
	location: undefined,
});

const defaultValues = {
	fetchCarta: () => {},
	menusData: undefined,
	menusDataError: false,
};

export const MenuDataContext = React.createContext(defaultValues);

export const MenuDataProvider = ({ children }) => {
	const [menusData, setMenusData] = React.useState(undefined);
	const [fetchedMenus, setFetchedMenus] = React.useState([]);

	const fetchCarta = async (restaurant, setState, setError) => {
		const APIKey = restaurant.frontmatter.cartaAPIKey;
		const spreadsheetId = "1wqGyykazNihBjggYSa8gkz9T2-cDCNDzGPRw-SpO2GE";
		const sheetName = restaurant.frontmatter.id;

		console.log(sheetName);

		if (
			menusData &&
			Object.keys(menusData).includes(restaurant.frontmatter.id)
		) {
			return setState(menusData[restaurant.frontmatter.id]);
		} else {
			if (!fetchedMenus.includes(restaurant.frontmatter.id)) {
				setFetchedMenus((prevState) => [
					...prevState,
					restaurant.frontmatter.id,
				]);
				axios
					.get(
						`https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}?key=${APIKey}`
					)
					.then((response) => {
						const cartaArray = [];

						const platos = response.data.values;

						for (var i = 1; i < platos.length; i++) {
							// we start at i = 1 cause i = 0 are headers

							var row = {}; // Create a new row object for each row
							for (var j = 0; j < 100; j++) {
								if (platos[i][j] === undefined) {
									break;
								}
								row[platos[0][j]] = platos[i][j];
							}
							cartaArray.push(row); // Push the row object to the output array
						}

						setMenusData((prevState) => ({
							...prevState,
							[restaurant.frontmatter.id]: cartaArray,
						}));
						setState(cartaArray);
					})
					.catch((error) => {
						console.error("Error:", error);
						setFetchedMenus((prevState) => [
							prevState.splice(prevState.indexOf(restaurant.frontmatter.id)),
						]);
						setError(error);
					});
			}
		}
	};

	return (
		<MenuDataContext.Provider
			value={{
				...defaultValues,
				fetchCarta,
			}}
		>
			{children}
		</MenuDataContext.Provider>
	);
};
