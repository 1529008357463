import * as React from "react";

// plugin imports

// utils imports

// svg imports

export const LocationContext = React.createContext({
	location: undefined,
});
