module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"G-GBE4W3GSQ7","anonymize":true},"facebookPixel":{"pixelId":"000000000","anonymize":true},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Voltereta Restaurante","short_name":"Voltereta","start_url":"/","lang":"es","background_color":"#667f61","theme_color":"#fdf8f1","display":"minimal-ui","icon":"src/svg/favicon.svg","localize":[{"start_url":"/en/","lang":"en","name":"Voltereta Restaurant","short_name":"Voltereta","description":"Go through the door of Voltereta and travel through all 5 senses. Our menus are inspired by the gastronomy of the different countries that we have been lucky enough to discover in our travels."},{"start_url":"/es/","lang":"es","name":"Voltereta Restaurante","short_name":"Voltereta","description":"Cruza la puerta de Voltereta y viaja a través de los 5 sentidos. Nuestra carta está inspirada en la gastronomía de los diferentes países que hemos tenido la suerte de conocer viajando."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"29436ca8ba448a36ab00e8e19a354f4c"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1800,"linkImagesToOriginal":false,"backgroundColor":"none","disableBgImageOnAlpha":true,"withWebp":true,"showCaptions":false,"markdownCaptions":false,"quality":50,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImage":false},
    },{
      plugin: require('../node_modules/@herob191/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["es","en"],"defaultLanguage":"es","siteUrl":"https://www.volteretarestaurante.com/","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false,"react":{"transSupportBasicHtmlNodes":false}},"pages":[{"matchPath":"/dev-404-page","languages":["es"]}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
